import React from 'react';
import classes from './RightSidebar.module.css';

const rightSidebar = (props) => {
    return(
        <div className = {classes.RightSidebar}>
       
        </div>
    );
}

export default rightSidebar;