import React from "react";
import classes from "./Scheme.module.css";
import { Link, withRouter } from "react-router-dom";

const scheme = (props) => {
  const imageBaseURL = "/media/";
  console.log("inside Scheme.js with scheme details: " + props.scheme);
  const getImageURL = () => {
    console.log(props.scheme);
    let imageURL = "";
    if (!props.match.params.state) {
      imageURL = imageBaseURL + "IN/" + props.scheme.schemeLogo;
    } else {
      const stateCode =
        props.match.params.state === "KA" ? "KR" : props.match.params.state;
      //console.log(stateCode)
      //console.log(props.match.params.state+"    "+props.department.departmentGridImage);
      const imageName = props.scheme.schemeLogo;
      imageURL = imageBaseURL + stateCode + "/" + imageName;
    }
    return imageURL;
  };

  return (
    <Link
      style={{ textDecoration: "none" }}
      className={classes.Scheme}
      to={{
        pathname: props.location.pathname + "/" + props.scheme.schemeID.value,
        //search : '?schemeID='+ props.scheme.schemeID.value
      }}
    >
      <div>
        <img
          src={getImageURL()}
          alt={props.scheme.schemeTitle.value + "LOGO"}
        ></img>
        <p> {props.scheme.schemeTitle.value} </p>
      </div>
    </Link>
  );
};

export default withRouter(scheme);
