import React, { Component } from "react";
import classes from "./MainArea.module.css";
import LeftSidebar from "../../components/Sidebar/LeftSidebar/LeftSidebar";
import RightSidebar from "../../components/Sidebar/RightSidebar/RightSidebar";
import CenterContent from "../../components/CenterContent/CenterContent";
import { Route, Redirect } from "react-router-dom";
import AboutUs from "../../components/About Us/AboutUs";
import Login from "../../components/Login/Login";
import Schemes from "../Schemes/Schemes";
import SchemeDetails from "../../components/CenterContent/Scheme/SchemeDetails/SchemeDetails";
//import schemeDetails from '../../components/CenterContent/Scheme/SchemeDetails/SchemeDetails';

class MainArea extends Component {
  componentDidMount() {
    // navigator.geolocation.getCurrentPosition(function(position) {
    //     console.log("Latitude is :", position.coords.latitude);
    //     console.log("Longitude is :", position.coords.longitude);
    //     const GEOCODING = 'https://www.mapquestapi.com/geocoding/v1/reverse?key=API-Key&location='+position.coords.latitude+'%2C'+position.coords.longitude+'&outFormat=json&thumbMaps=false';
    //     axios.get(GEOCODING).
    //     then(response=>{
    //         console.log(response.data)
    //     })
    //   });
  }
  render() {
    console.log(this.props);

    return (
      <div className={classes.MainArea}>
        <LeftSidebar></LeftSidebar>

        <Route
          path={"/home/:state/:language"}
          exact
          render={(props) => <CenterContent {...props} />}
        ></Route>

        <Route
          path={"/home/:state/:language/:DepID"}
          exact
          render={(props) => <Schemes {...props} />}
        ></Route>

        <Route
          path={"/home/:state/:language/:DepID/:schemeID"}
          exact
          render={(props) => <SchemeDetails {...props} />}
        ></Route>

        <Route path="/aboutus" exact render={() => <AboutUs />}></Route>

        <Route path="/login" exact render={() => <Login />}></Route>

        <Route path="/" exact>
          <Redirect to="/home/IN/en"></Redirect>
        </Route>

        <Route path="/home" exact>
          <Redirect to="/home/IN/en"></Redirect>
        </Route>

        <RightSidebar></RightSidebar>
      </div>
    );
  }
}

export default MainArea;
