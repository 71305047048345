import {Component} from 'react';
//import Aux from './Aux';
import Toolbar from '../components/Toolbar/Toolbar';
import MainArea from '../containers/MainArea/MainArea';
import Footer from '../components/Footer/Footer';
import {BrowserRouter, Route} from 'react-router-dom';
import MetaTags from 'react-meta-tags';
class Layout extends Component {
    render() {
        return(
            <BrowserRouter>
            <MetaTags>
        <meta http-equiv="Content-Security-Policy" content="default-src 'self'"></meta>
        </MetaTags>
            <Route path='/' component= {Toolbar}></Route>
            {/* <Route path='/' component={MainArea}></Route> */}
            {/* <MainArea></MainArea> */}
            <MainArea></MainArea>
            <Route path='/' component={Footer}></Route>
            
            
        
            </BrowserRouter>
            
        );
        
    }
}

export default Layout;