import React from 'react';
import classes from './AboutUs.module.css';

const aboutUs = (props) => {
    return(
        <div className = {classes.AboutUs}>
            <h3>About Us</h3>
            <p>Upaya Technologies is a forward-looking company focused on building modern products and solutions.
                As an information and technology company , we implement innovative ideas to provide 
                services ranging form commom man to business and financial institutions.
            </p>
            <h3>Contact Us</h3>
            <h5>Email:</h5>
            <p>info@upayatechnologies.com</p>
            <h5>Address:</h5>
            <p>
            Upaya Technologies Private Limited, India
            <br/>Sy NO. 34/4, 13 Nallurahalli
            <br/>Whitefield, Karnataka-560066
            </p>
            <h3>Privacy/Terms & Conditions</h3>
            <h5>Introduction</h5>
            <p>
            We appreciate your interest in us. As you navigate this site andcommunicate with us
            via this site, we may ask you to disclose certain personal information so that we can
            provide you with the highest quality information and services. In order for you to 
            make an informeddecision about whether to disclose your personal information to us 
            via this site, we are providing youwith the following information about how we collect 
            and use personal information. By "personalinformation," we mean data that is unique to an 
            individual, such as a name, address, email address ortelephone number.
            </p>

            <h5>Upaya gov schemes app content disclaimer</h5>
            <p>
            The schemes details provided in the Upaya app are from respective government websites and 
            portalswhich are available for public. We re-created and structured the content which is 
            available for public ina better format to easily understandable by the people . We are not
             related or affiliated to any government entity or agency. We keep on working and try to 
             provide accurate information as much as possible. Below are reference public government 
             sites we referred.https://www.ap.gov.in, https://www.tn.gov.in, https://scholarships.gov.in.
            </p>

            <h5>Types of Information we collect</h5>
            <p>
            We value the trust you place in us. We take great care to protect the information you 
            share with us. Thepolicies and procedures we follow are strict. They must be to ensure 
            your personal information remainssecure. We have adopted and adhere to stringent security 
            standards designed to protect non-publicpersonal information at www.UpayaTechnoligies.com 
            against accidental or unauthorized access or disclosure.Among the safeguards that 
            UpayaTechnoligies has developed for this site are administrative, physical andtechnical 
            barriers that together protect this web site and our other internal systems from unauthorizedaccess 
            or use. We periodically subject our site to simulated intrusion tests and have developedcomprehensive 
            disaster recovery plans.
            </p>

            <h5>Use of information</h5>
            <p>
            You should also be aware that when you visit our website, we collect certain non-personally-identifiableand 
            aggregate information about you. This data helps us to analyze and improve the usefulness of theinformation 
            we provide at this website. In order for you to make an informed decision about whether touse and navigate 
            our website, we are providing you with the following information about how we collectand use non-personally 
            identifiable and aggregate information. By personally-identifiable information,we mean information that does 
            not identify you personally, but provides us with anonymous "usagedata," such as the number of unique visitors 
            we receive, what pages are visited most often and thenavigation preferences and characteristics of our visitors.
            </p>

            <h5>Contact Information</h5>
            <p>
            From time to time, our website may provide links to other websites, not owned or controlled 
            byUpayaTechnologies Software Systems, Inc. that we think might be useful or of interest to you. 
            We cannot,however, be responsible for the privacy practices used by other website owners or the 
            content oraccuracy of those other websites. Links to various non-UpayaTechnologies websites do 
            not constitute or implyendorsement by UpayaTechnologies of these websites, any products or services 
            described on these sites, or ofany other material contained in them. We may also provide links to 
            other websites that are controlledby UpayaTechnologies, but that operate independently of 
            www.UpayaTechnologies.com Privacy policies applicable tothose sites will appear on those sites 
            and may differ from this www.UpayaTechnologies.com PrivacyStatement.UpayaTechnologies Software 
            Systems, Inc. may change this statement from time to time; when updates aremade, the privacy 
            policy version date (located at the bottom of this policy) will also be updated toreflect that 
            a revision occurred. We encourage you to periodically reread this policy to see if there havebeen 
            any changes that may affect you. This statement is not intended to and does not create anycontractual 
            or other legal rights in or on behalf of any party.
            </p>

            <h5>Use of information</h5>
            <p>
            You should also be aware that when you visit our website, we collect certain non-personally-
            identifiableand aggregate information about you. This data helps us to analyze and improve 
            the usefulness of theinformation we provide at this website. In order for you to make an 
            informed decision about whether touse and navigate our website, we are providing you with 
            the following information about how we collectand use non-personally identifiable and aggregate 
            information. By personally-identifiable information,we mean information that does not identify 
            you personally, but provides us with anonymous "usagedata," such as the number of unique visitors 
            we receive, what pages are visited most often and thenavigation preferences and characteristics 
            of our visitors.
            </p>

            <h5>Changes to this Privacy Policy</h5>
            <p>
            You should also be aware that when you visit our website, we collect certain non-personally-identifiableand 
            aggregate information about you. This data helps us to analyze and improve the usefulness of theinformation 
            we provide at this website. In order for you to make an informed decision about whether touse and navigate 
            our website, we are providing you with the following information about how we collectand use non-personally 
            identifiable and aggregate information. By personally-identifiable information,we mean information that does 
            not identify you personally, but provides us with anonymous "usagedata," such as the number of unique visitors 
            we receive, what pages are visited most often and thenavigation preferences and characteristics of our visitors.
            </p>
        </div>
    )
}

export default aboutUs;