import React, { Component } from "react";
import classes from "./Schemes.module.css";
import axios from "axios";
import Scheme from "../../components/CenterContent/Scheme/Scheme";

class schemes extends Component {
  state = {
    state: null,
    language: null,
    depId: null,
    schemes: [],
    error: null,
  };

  static getDerivedStateFromProps(props, state) {
    //console.log(props)
    return {
      state: props.match.params.state,
      language: props.match.params.language,
      depId: props.match.params.DepID,
    };
  }

  componentDidMount() {
    this.setState({
      error: null,
    });
    axios
      .get(
        "/data/" +
          this.state.state +
          "/" +
          this.state.language +
          "/Schemes.json"
      )
      .then((response) => {
        console.log(
          "/data/" +
            this.state.state +
            "/" +
            this.state.language +
            "/Schemes.json"
        );
        console.log(response.data);
        console.log(typeof response.data);
        this.setState({
          schemes: response.data.schemes[this.props.match.params.DepID],
        });
        //console.log(response.data.schemes[this.props.match.params.DepID])
      })
      .catch((error) => {
        console.log("error is: " + error);
        this.setState({
          error: error,
        });
      });
  }

  render() {
    let schemeTiles = null;
    if (this.state.schemes) {
      schemeTiles = this.state.schemes.map((scheme) => {
        console.log(scheme);
        return <Scheme scheme={scheme} key={scheme.schemeTitle.value}></Scheme>;
      });
    }

    if (this.state.error) {
      schemeTiles = (
        <p>Something went wrong in Schemes. Please try again later.</p>
      );
    }

    //console.log("displaying Modal: " + this.state.error)
    return (
      <div className={classes.Schemes}>
        {schemeTiles}
        {/* <Modal message='Testing Modal'></Modal> */}
      </div>
    );
  }
}

export default schemes;
