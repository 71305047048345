import React from 'react';
import classes from './LeftSidebar.module.css';

const leftSidebar = (props) => {
    return(
        <div className = {classes.LeftSidebar}>
            
        </div>
    );
}

export default leftSidebar;