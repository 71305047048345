import React,{Component} from 'react';
import classes from './SchemeDetails.module.css';
import WithClass from '../../../../hoc/WithClass';
import axios from 'axios';

class SchemeDetails extends Component {
      
    state = {
        schemeDetails: null,
        nonNeededKeys: ['Scheme Id','departmentID'],
        imageBaseURL : '/media/'
    }

    componentDidMount(){
        //console.log('componentdidMount')
        //console.log(this.props.match.params.state+"  "+ this.props.match.params.language+" "+this.props.match.params.DepID+" "+this.props.match.params.schemeID);
        axios.get('/data/'+this.props.match.params.state+'/'+this.props.match.params.language+'/Schemes.json')
        .then(response=>{
            response.data.schemes[this.props.match.params.DepID].map(scheme=>{
                //console.log(scheme.schemeID.value)
                if(scheme.schemeID.value===this.props.match.params.schemeID){
                    this.setState({
                        schemeDetails:scheme
                    })
                    //console.log('stateupdated with scheme')
                    //console.log(this.state.schemeDetails)
                    
                }
                return null;
            })
        })
    }

    getImageURL(){
        let imageURL = Object.keys(this.state.schemeDetails).map(el=>{
            if(el === 'schemeLogo'){
                //console.log(this.state.schemeDetails[el])
                //return this.state.schemeDetails[el]
                //console.log(this.state.imageBaseURL+this.props.match.params.state+'/'+this.state.schemeDetails[el])
                return this.state.imageBaseURL+this.props.match.params.state+'/'+this.state.schemeDetails[el];
            }
            else{
                return null;
            }
        })
        //console.log(imageURL);
        return imageURL.filter(el=>el!==null);
        
    }

    render(){
        let loading = <p>loading..............</p>
        let schemeDetailsJSX = null;
        if(this.state.schemeDetails){
            //console.log(this.state.schemeDetails)
            schemeDetailsJSX = Object.keys(this.state.schemeDetails).map(el=>{
                if(el === 'schemeLogo'||el==='departmentID'){
                    //console.log('this is logo');
                    return null;
                }
                if(this.state.nonNeededKeys.includes(this.state.schemeDetails[el].label)){
                    //console.log('Inside non needed')
                    return null;
                }
                else{
                    //console.log(this.state.schemeDetails[el])
                 return <div key = {this.state.schemeDetails[el].label}>
                <p className={classes.label}>{this.state.schemeDetails[el].label}</p>
                <p >{this.state.schemeDetails[el].value}</p>
                </div>
                 
                }
                
            })
        }
        let loadImage = null;
        if(this.state.schemeDetails){
            //console.log(this.getImageURL())
         loadImage = <img src={this.getImageURL()} alt = 'logo'></img>
        }
        
        
        return(
        
            <WithClass classes={classes.SchemeDetails}>
                    <div>
                        {this.state.schemeDetails?loadImage:loading}
                        {this.state.schemeDetails?schemeDetailsJSX:loading}
                    </div>
            </WithClass>
        );
    }

  
    
}

export default SchemeDetails;