import React from 'react';
import classes from './Logo.module.css';
import upayalogo from '../../../assets/upayalogo.jpg';
import {Link} from 'react-router-dom';

const logo = props => {
    return (
        <Link to='/' style={{textDecoration:'none'}}>
         <div className={classes.Logo}>
            <img src={upayalogo} alt='Upaya Gov Schemes'></img>
            
        </div>
        </Link>
       
    );
}

export default logo;