import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import classes from './Department.module.css';

const department = (props) => {
    console.log("LOGO"+props.department.departmentLogo);
    console.log(props)
    
    const imageBaseURL = '/media/'

   

    const getImageURL= ()=>{
        let imageURL = '';
        if(!props.match.params.state){
            imageURL = imageBaseURL+'IN/'+props.department.departmentGridImage;
        }
        else{
            //const stateCode = props.match.params.state ==='KA'?'KR':props.match.params.state;
        //console.log(stateCode)
        //console.log(props.match.params.state+"    "+props.department.departmentGridImage);
        const imageName = props.department.departmentGridImage
         imageURL = imageBaseURL+props.match.params.state+'/'+imageName;
        
        }
        return imageURL;
    }
   

    return(
       
    <Link style={{ textDecoration: 'none' }} className = {classes.Department} to={props.location.pathname+'/'+props.department.departmentID}> 
    <div>
    <img src = {getImageURL()}
    alt = {props.department.departmentTitle+'LOGO'}>
    </img>
    <p> {props.department.departmentTitle} </p> 
    </div>
          
    </Link>
    );
}

export default withRouter(department);