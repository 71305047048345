import React, {Component} from 'react';
import Aux from './hoc/Aux-Temp';
import Layout from './hoc/Layout';

class App extends Component {

  render(){
    return (
       
      <Aux>
        
        <Layout>
        
        </Layout>
      </Aux>
    );
  }
  }

export default App;
