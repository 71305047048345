import React from 'react';
import classes from './Footer.module.css';

const footer = (props) => {
    return(
        <div className = {classes.Footer}>
            <p>Copyright © 2020-2021 UpayaTechnologies. All rights reserved. &nbsp;
                <a href = '/aboutUs'>Terms of Use</a> |  &nbsp;
                <a href = '/aboutUs'>Privacy Policy </a>
            </p>
        </div>
    );

}

export default footer;