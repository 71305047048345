import React, { Component } from "react";
import Aux from "../../hoc/Aux-Temp";
import Logo from "../UI/Logo/Logo";
import classes from "../Toolbar/Toolbar.module.css";
import WithClass from "../../hoc/WithClass";
import NavigationItems from "../NavigationItems/NavigationItems";
import axios from "axios";

class Toolbar extends Component {
  state = {
    states: [],
    selectedState: null,
    allLanguages: {},
    languages: [],
    selectedLanguage: null,
    defaultState: "IN",
    defaultLanguage: "en",
  };

  componentDidMount() {
    axios.get("/country/IN/states.json").then((response) => {
      //console.log(typeof response.data)
      this.setState({ states: response.data.states });
      //console.log(this.state.states)
    });

    axios.get("/country/IN/language.json").then((response) => {
      const language = response.data.langauge;
      this.setState({ allLanguages: language });
      //console.log(language);
    });
  }

  languageChangeHandler(event) {
    this.state.languages.map((langObj) => {
      if (langObj.value === event.target.value) {
        this.setState({
          selectedLanguage: langObj.id,
        });
      } else {
        this.setState({
          selectedLanguage: this.state.defaultLanguage,
        });
      }
      return null;
    });

    //console.log(this.props.location.pathname);
  }

  updateSelectedState(event) {
    this.setState({ selectedState: event.target.value });
    //console.log(this.state.allLanguages.TN);
    Object.keys(this.state.allLanguages).map((stateCode) => {
      if (event.target.value === stateCode) {
        //console.log('State Matched for' + stateCode +'and languages are' + this.state.allLanguages[stateCode])
        const tempLanguages = [];
        this.state.allLanguages[stateCode].map((langObj) => {
          tempLanguages.push(langObj);
          this.setState({
            languages: tempLanguages,
            selectedLanguage: this.state.defaultLanguage,
          });
          return null;
        });
        return null;
      } else {
        return null;
      }
    });
  }

  stateAndLanguageSelectHandler() {
    if (this.state.selectedLanguage && this.state.selectedState) {
      console.log(this.state.selectedState + " " + this.state.selectedLanguage);
      this.props.history.push({
        pathname:
          "/home/" +
          this.state.selectedState +
          "/" +
          this.state.selectedLanguage,
      });
    }
    if (this.state.selectedState && !this.state.selectedLanguage) {
      // console.log(this.state.selectedState+"  "+this.state.defaultLanguage)
      this.props.history.push({
        pathname:
          "/home/" +
          this.state.selectedState +
          "/" +
          this.state.defaultLanguage,
      });
    }
    if (!this.state.selectedState && this.state.selectedLanguage) {
      // console.log(this.state.defaultState+"  "+this.state.defaultLanguage)
      this.props.history.push({
        pathname:
          "/home/" +
          this.state.defaultState +
          "/" +
          this.state.selectedLanguage,
      });
    }
    if (!this.state.selectedState && !this.state.selectedLanguage) {
      //console.log(this.state.defaultState+"  "+this.state.defaultLanguage)
      this.props.history.push({
        pathname:
          "/home/" + this.state.defaultState + "/" + this.state.defaultLanguage,
      });
    }
  }

  render() {
    //console.log(this.props)
    const stateOptions = this.state.states.map((state) => {
      return (
        <option key={state.id} value={state.id}>
          {state.value}
        </option>
      );
    });

    const langOptions = this.state.languages.map((langObj) => {
      return <option key={langObj.id}>{langObj.value}</option>;
    });

    return (
      <Aux>
        <WithClass classes={classes.Toolbar}>
          <Logo></Logo>
          {/* <input
            placeholder="Search"
            className={classes.Search}
            type="text"
          ></input> */}
          <div>
            <label htmlFor="State">State</label>
            <select
              onChange={(event) => this.updateSelectedState(event)}
              name="State"
              id="State"
            >
              <option value={this.state.defaultState}>India</option>
              {stateOptions}
            </select>

            <label htmlFor="language">Language</label>
            <select
              onChange={(event) => this.languageChangeHandler(event)}
              name="language"
              id="language"
            >
              <option value={this.state.defaultLanguage}>
                Select Language
              </option>
              {langOptions}
            </select>
            <button
              onClick={() => this.stateAndLanguageSelectHandler()}
              className={classes.Button}
            >
              GO
            </button>
          </div>
        </WithClass>

        <div>
          <NavigationItems
            selectedState={
              this.state.selectedState
                ? this.state.selectedState
                : this.state.defaultState
            }
            selectedLanguage={
              this.state.selectedLanguage
                ? this.state.selectedLanguage
                : this.state.defaultLanguage
            }
          ></NavigationItems>
        </div>
      </Aux>
    );
  }
}

export default Toolbar;
