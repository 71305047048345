import React from 'react';
import classes from './NavigationItem.module.css';
import {NavLink} from 'react-router-dom';

const navigationItem = (props) => {
    return(
        <div className={classes.MenuItem}>
                <NavLink to={props.to} 
                activeClassName='active-link' 
                activeStyle={{
                    backgroundColor: 'white',
                    fontWeight: '900',  
                    color: 'black'
                }}>{props.name}</NavLink>
        </div>
    // <li className = {classes.MenuItem}>{props.name}</li>
    
    );
}

export default navigationItem;