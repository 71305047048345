import React from 'react-router-dom';
import classes from './Login.module.css';
import construction from '../../assets/construction.jpg'



const login = (props) => {
    return(
        <div className = {classes.Login}>
            
            <h3 style={{textAlign:'center'}}> We are still working on this and would be able to serve you better soon. Thank you for your patience.</h3>
               <img style={{width: '100%', alignItems:'center'}}src={construction} alt='UNDER CONSTRUCTION'></img> 
         </div>
    );
}

export default login;