import React, { PureComponent } from "react";
import classes from "./CenterContent.module.css";
import axios from "axios";
import Department from "./Departments/Department/Department";

class CenterContent extends PureComponent {
  state = {
    selectedState: null,
    selectedLanguage: null,
    departments: null,
    dataupdated: false,
  };

  static getDerivedStateFromProps(props, state) {
    console.log(props);
    if (
      props.location.pathname === "/" ||
      props.location.pathname === "/home"
    ) {
      return {
        selectedState: "IN",
        selectedLanguage: "en",
      };
    } else {
      return {
        selectedState: props.match.params.state,
        selectedLanguage: props.match.params.language,
      };
    }
  }

  componentDidMount() {
    
    console.log(
      "Inside component did mount for center content js for state - Test 001:" +
        this.state.selectedState +
        " and language:" +
        this.state.selectedLanguage
    );
    axios
      .get(
        "/data/" +
          this.state.selectedState +
          "/" +
          this.state.selectedLanguage +
          "/Departments.json",
        { withCredentials: false }
      )
      .then((response) => {
        this.setState({
          departments: response.data.departments,
          dataupdated: true,
        });
        console.log(this.state.departments);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('inside compdidupate')
    //console.log(this.props.match.params.state)
    //console.log(this.props.match.params.state!==prevState.selectedState)
    //console.log(this.props.match.params.language!==prevState.selectedLanguage)
    if (
      this.props.match.params.state &&
      (this.props.match.params.state !== prevState.selectedState ||
        this.props.match.params.language !== prevState.selectedLanguage)
    ) {
      this.setState({ departments: null });
      console.log(
        "sending a rest call to get deps with language::" +
          this.props.match.params.language
      );
      axios
        .get(
          "/data/" +
            this.state.selectedState +
            "/" +
            this.state.selectedLanguage +
            "/Departments.json",
          { withCredentials: true }
        )
        .then((response) => {
          this.setState({
            departments: response.data.departments,
            dataupdated: true,
          });

          console.log(this.state.departments);
        });
    }
  }

  render() {
    let departmentTiles = "Something Went wrong. Please Try again later.";
    console.log(
      "inside center content render method::" + this.state.Departments
    );
    if (
      this.state.dataupdated &&
      this.state.departments &&
      this.state.selectedState
    ) {
      //if(this.state.departments && this.state.selectedState){
      departmentTiles = this.state.departments.map((department) => {
        //console.log(department.departmentID)
        return (
          <Department
            key={department.departmentID}
            department={department}
          ></Department>
        );
      });

      //console.log(departmentTiles);
    }
    //console.log(departmentTiles);
    return <div className={classes.CenterContent}>{departmentTiles}</div>;
  }
}

export default CenterContent;
