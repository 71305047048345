import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem';
import WithClass from '../../hoc/WithClass';
import classes from './NavigationItems.module.css';


const navigationItems = (props) => {
    return(
        <WithClass classes = {classes.NavItem}>
             <NavigationItem to = '/login' name = 'Login'></NavigationItem>
             <NavigationItem to = '/aboutus' name = 'About Us'></NavigationItem>
            <NavigationItem to={'/home/' + props.selectedState+'/'+props.selectedLanguage} name = 'Home'></NavigationItem>
            
        </WithClass>
    );
}

export default navigationItems;